import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { AppActions } from '../store/configureStore';
import { useGetCreditSummary } from './useGetCreditSummary';
import { useGetEstimatedOffers } from './useGetEstimatedOffers';
import { useGetEstimatedOfferSelected } from './useGetEstimatedOffer';
import { useGetVehicleInfo } from './useGetVehicleInfo';

type Data = {
  nextScreen: string;
  vinNumber: string;
  assetId: string;
  isVinEntered: boolean;
  lastCompletedScreen: string;
};

type Params = {
  actions: AppActions;
  creditSummary: {
    ficoDetails?: { name: string; scoreType: string; score: string }[];
    loanDetails?: {
      lender: string;
      currentBalance: string;
      openDate: string;
      payment: string;
      remainingTerm: string;
    }[];
  };
  estimatedOffers: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors?: { [key: string]: string };
  };
  estimatedOfferSelected: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    };
    errors?: { [key: string]: string };
  };
  vehicleInfo: {
    data?: { [key: string]: string };
    errors?: { [key: string]: string };
    loading?: boolean;
  };
  data?: Data;
};

export const useRedirectToScreen = ({ data, ...params }: Params) => {
  const navigate = useNavigate();
  const [getCreditSummary] = useGetCreditSummary(params);
  const [getEstimatedOffers] = useGetEstimatedOffers(params);
  const [getEstimatedOfferSelected] = useGetEstimatedOfferSelected(params);
  const {getVehicleInfoFromVIN} = useGetVehicleInfo(params);
  const [isRunning, setIsRunning] = useState(false);

  const screenToURL: Record<string, string | ((data: Data) => string)> = useMemo(
    () => ({
      AboutYou: '/',
      //Qualifier: '/qualifier',
      VehicleInfo: '/vehicle-info',
      VehicleId: '/vehicle-id',
      VehicleMoreInfo: ({ vinNumber }) => {
        getVehicleInfoFromVIN(vinNumber);
        return '';
      },
      VehicleNoVin: ({ isVinEntered }) => {
        if (isVinEntered) params.actions.phoenixGetVehicleInfoFromVINSuccess({ isVinFound: false });
        return '/vehicle-no-vin';
      },
      PlateToVin: '/vehicle-plate',
      RequestSsn: '/request-ssn',
      EstimatedCreditScore: '/credit-score',
      LoanDetails: ({ lastCompletedScreen }) => {
        let loanUrl = '/loan-info';
        if (lastCompletedScreen === 'RequestSsn') {
          getCreditSummary();
          loanUrl = '';
        }
        return loanUrl;
      },
      MissingLoanDetails: '/loan-info',
      EstimatedOfferScreen: () => {
        getEstimatedOffers();
        return '';
      },
      EstimatedOfferSelected: '/off-ramp',
      ThankYouPage: '/off-ramp',
      Address: '/address',
      NewAddress: '/address',
      CurrentAddress: '/currentaddress',
      OffRamp: () => {
        getEstimatedOfferSelected();
        return '/off-ramp';
      },
      ZeroOffer: () => {
        getEstimatedOfferSelected();
        return '/off-ramp';
      },
    }),
    [
      getCreditSummary,
      getVehicleInfoFromVIN,
      getEstimatedOffers,
      getEstimatedOfferSelected,
      params.actions,
    ]
  );

  useEffect(() => {
    if (data && !isRunning) {
      setIsRunning(true);
      console.log(data);
      const { nextScreen: screen } = data;
      if (!screen) {
        params.actions.clearVehicleInfo();
        params.actions.setError({});
        params.actions.setIsLoading(false);
      } else {
        let url = screenToURL[screen];
        if (typeof url !== 'string') url = url(data);
        if (url) {
          navigate(url, { replace: true });
          params.actions.setIsLoading(false);
        }
      }
    }
  }, [data, screenToURL, navigate, isRunning, params.actions]);
};
