import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import { AppActions } from '../../store/configureStore';
import requestSSNConsentSchema from '../../schemas/RequestSSNConsent';
import CustomButton from '../CustomButton/CustomButton';
import useEffectOnce from '../../utils/useEffectOnce';

type Contacts = {
  results: {
    contactId: string;
  };
};

export type Props = {
  actions: AppActions;
  contacts: Contacts;
};

const initialValues = {
  agreement: false,
};

const AgreementText = styled.span(props => ({
  color: props.theme.palette.gray.main,
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '0.15px',
}));

const AgreementCheckbox = styled(Checkbox)(props => ({
  alignSelf: 'start',
  marginTop: -1,
  '&.Mui-checked': { color: props.theme.palette.green.main },
  paddingTop: '3px',
}));
const AgreementInfoIcon = styled(InfoIcon)(props => ({
  color: props.theme.palette.teal.main,
  fontSize: 14,
  marginLeft: '-6px',
  lineHeight: '20px',
}));

const RequestSSNConsent = ({ actions, contacts }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffectOnce(() => actions.analyticsTrackPage('RequestSSN'));

  const { values, errors, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: requestSSNConsentSchema(),
      onSubmit: values => {
        actions.analyticsTrackFormSubmit({ eventType: 'UpdateSSN' });
        actions.phoenixUpdateSSN({
          contactId: contacts.results.contactId,
        });
        navigate('/loan-info', { replace: true });
      },
      validateOnMount: true,
    });

  return (
    <form onSubmit={handleSubmit}>
      <ContainerFooter>
        <FormControl
          error={!!errors.agreement}
          component="fieldset"
          sx={{ mt: 3 }}
          variant="standard"
        >
          <FormControlLabel
            control={
              <AgreementCheckbox
                id="agreement"
                name="agreement"
                checked={values.agreement}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            label={
              <AgreementText theme={theme}>
                {t('requestSSNConsent.agreement')}
                <Tooltip arrow title={t('requestSSNConsent.agreementTooltip')}>
                  <IconButton>
                    <AgreementInfoIcon />
                  </IconButton>
                </Tooltip>
              </AgreementText>
            }
          />
          <FormHelperText>{errors.agreement}</FormHelperText>
        </FormControl>

        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('requestSSNConsent.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default RequestSSNConsent;
