import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme, useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import PhoneTextField from '../PhoneTextField/PhoneTextField';
import ContainerFooter from '../ContainerFooter/ContainerFooter';
import CustomButton from '../CustomButton/CustomButton';
import aboutYouSchema from '../../schemas/AboutYou';

import { ContactsData } from '../../services/phoenix';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';
import useQuerystring from '../../utils/useQuery';
import Logger from '../../libs/Logger';
import { PRIMARY_SOURCE, SECONDARY_SOURCE } from '../../shared/constants/utm';

const logger = new Logger('LoanInfoForm');

export type Props = {
  actions: AppActions;
  contacts: {
    errors: { [key: string]: string };
    results: { [key: string]: string };
  };
};

const initialValues: ContactsData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  agreement: false,
};

const AgreementText = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.gray.main};
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

const AgreementCheckbox = styled(Checkbox)(props => ({
  alignSelf: 'start',
  marginTop: props.theme.spacing(-1),
  '&.Mui-checked': {
    color: props.theme.palette.green.main,
  },
}));

const AboutYouForm = (props: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryParams = useQuerystring();
  const { search: qs } = useLocation();

  useEffectOnce(() => {
    if (queryParams.resumeapp) {
      if (!queryParams.leadid || !queryParams.contactid) props.actions.setError({});
      else {
        props.actions.setIsLoadingNoWait({ isLoading: true });
        props.actions.phoenixGetProgressHistory({
          leadId: queryParams.leadid,
          contactId: queryParams.contactid,
        });
        navigate('/active-lead', { replace: true });
      }
    }
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: aboutYouSchema(),
      onSubmit: formValues => {
        logger.log(formValues);
        const values = { ...formValues, metadata: queryParams };
        const defaultUtm = {
          ...queryParams,
          primarySourceId: queryParams.utm_medium || PRIMARY_SOURCE,
          secondarySourceId: queryParams.secondary_source || SECONDARY_SOURCE,
        };
        props.actions.analyticsTrackClick({ eventType: 'UTM Parameters', ...defaultUtm });
        props.actions.analyticsTrackFormSubmit({ eventType: 'AboutYou Form Submit', ...values });
        props.actions.phoenixPostContact({
          ...formValues,
          metadata: encodeURIComponent(qs.replace('?', '')),
        });
      },
      validateOnMount: true,
    });
  useEffectOnce(() => props.actions.analyticsTrackPage('AboutYou'));

  useEffect(() => {
    if (props.contacts.results) {
      if (props.contacts.results.activeLeads && props.contacts.results.activeLeads.length > 0) {
        navigate('/active-lead', { replace: true });
      } else {
        navigate('/vehicle-id', { replace: true });
      }
    }
  }, [props.contacts, navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          id="firstName"
          name="firstName"
          label={t('aboutYou.form.firstName')}
          variant="filled"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <TextField
          id="lastName"
          name="lastName"
          label={t('aboutYou.form.lastName')}
          variant="filled"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <TextField
          id="email"
          name="email"
          label={t('aboutYou.form.email')}
          variant="filled"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <PhoneTextField
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          InputProps={{
            sx: {
              bgcolor: 'lighterGray.main',
            },
          }}
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        {/* <Box pl={4}>
          <AgreementText theme={theme}>{t('aboutYou.form.agreementAck')}</AgreementText>
        </Box> */}
      </Stack>
      <ContainerFooter>
        <FormControl
          error={!!errors.agreement}
          component="fieldset"
          sx={{ mt: 3 }}
          variant="standard"
        >
          <FormControlLabel
            control={
              <AgreementCheckbox
                id="agreement"
                name="agreement"
                checked={values.agreement}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            sx={{
              marginRight: 0,
            }}
            label={
              <AgreementText theme={theme}>
                {t('aboutYou.form.continueAck')}{' '}
                <Link
                  href="https://ilendingcarloanrefinancing.com/sms-terms-of-use/"
                  target="_blank"
                >
                  {t('aboutYou.form.smsTerms')}
                </Link>
                ,{' '}
                <Link href="https://ilendingcarloanrefinancing.com/privacy-policy/" target="_blank">
                  {t('aboutYou.form.privacyPolicy')}
                </Link>{' '}
                &{' '}
                <Link href="https://ilendingcarloanrefinancing.com/terms-of-use/" target="_blank">
                  {t('aboutYou.form.termsOfUse')}
                </Link>  {t('aboutYou.form.dataRatesMayApply')}
              </AgreementText>
            }
          />
          <FormHelperText>{errors.agreement}</FormHelperText>
        </FormControl>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('aboutYou.form.continueButton')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default AboutYouForm;
