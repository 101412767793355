import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import RequestSSNConsent, { Props } from '../components/RequestSSNConsent/RequestSSNConsent';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const RequestSSNConsentContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    contacts: phoenixRoot.contacts as Props['contacts'],
  };

  return <RequestSSNConsent {...props} />;
};

export default RequestSSNConsentContainer;
