import { takeLatest, put, all, select, call, take } from 'redux-saga/effects';
import { get } from 'lodash';
import { AnyAction } from 'redux';

import ActionTypes from '../actions/actionTypes';
import * as actions from '../actions/actions';
import * as selectors from './selectors';

import Logger from '../libs/Logger';
import { RootState, PayloadAction } from '../store/configureStore';
import * as phoenix from '../services/phoenix';
import { RECAPTCHA_ERROR_MESSAGE } from '../services/phoenix/utils';

const logger = new Logger('phoenixSaga');

function* postContact({ payload }: PayloadAction<phoenix.ContactsData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Phoenix postContacts -> ', appData, payload);

  try {
    const postResults: phoenix.ContactsResponse = yield call(phoenix.postContact, payload);
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'message' in postResults) {
      yield put(actions.phoenixPostContactError(postResults || {}));
      yield put(actions.setError(postResults || {}));
    } else yield put(actions.phoenixPostContactSuccess(postResults));
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixPostContactError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* postQualifiers({ payload }: PayloadAction<phoenix.QualifiersData>) {
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const newPayload = { disqualifiers: payload, ...get(phoenixData, 'contacts.results', {}) };
  logger.log('Phoenix postQualifiers -> ', phoenixData, payload, newPayload);

  try {
    const postResults: phoenix.QualifiersResponse = yield call(phoenix.postQualifiers, newPayload);
    logger.log('Phoenix response -> ', postResults);
    if (
      postResults &&
      'message' in postResults &&
      postResults.message.includes(RECAPTCHA_ERROR_MESSAGE)
    ) {
      yield put(actions.setError(postResults));
    }
    if (postResults) yield put(actions.phoenixPostQualifiersSuccess(true));
  } catch (err) {
    //
  }
}

function* postAssets({ payload }: PayloadAction<phoenix.AssetsDataPost>) {
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Phoenix postAssets -> ', appData, payload);
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const newPayload = { ...payload, ...get(phoenixData, 'contacts.results', {}) };

  try {
    const postResults: phoenix.ContactsResponse = yield call(phoenix.postAssets, newPayload);
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'message' in postResults) {
      yield put(actions.phoenixPostAssetsError(postResults));
      if ('message' in postResults && postResults['message'].includes(RECAPTCHA_ERROR_MESSAGE))
        yield put(actions.setError(postResults || {}));
    } else yield put(actions.phoenixPostAssetsSuccess(postResults));
  } catch (err) {
    yield put(actions.phoenixPostAssetsError());
  }
}

function* postAsset({ payload }: PayloadAction<phoenix.AssetDataPost>) {
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Phoenix postAsset -> ', appData, payload);
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const assetId =
    get(phoenixData, 'vehicleInfo.data.assetId', '') || get(phoenixData, 'asset.data.assetId', '');
  const newPayload = { ...payload, ...get(phoenixData, 'contacts.results'), assetId };
  try {
    const postResults: phoenix.ContactsResponse = yield call(phoenix.postAsset, newPayload);
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'message' in postResults) {
      yield put(actions.phoenixPostAssetError(postResults));
      yield put(actions.setError(postResults || {}));
    } else yield put(actions.phoenixPostAssetSuccess(postResults));
  } catch (err) {
    yield put(actions.setError(err));
    yield put(actions.phoenixPostAssetError({}));
  }
}

function* putAsset({ payload }: PayloadAction<phoenix.AssetDataPut>) {
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Phoenix putAsset -> ', appData, payload);
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const leadId = get(phoenixData, 'contacts.results.leadId', '');
  const assetId = get(phoenixData, 'vehicleInfo.data.assetId', '');
  const newPayload = { ...payload, leadId, assetId };
  try {
    const postResults: phoenix.ContactsResponse = yield call(phoenix.putAsset, newPayload);
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'message' in postResults) {
      yield put(actions.phoenixPutAssetError(postResults));
      if ('message' in postResults && postResults['message'].includes(RECAPTCHA_ERROR_MESSAGE))
        yield put(actions.setError(postResults));
    } else yield put(actions.phoenixPutAssetSuccess(postResults));
  } catch (err) {
    yield put(actions.phoenixPutAssetError());
  }
}

function* getVehicleInfoFromVIN({ payload }: AnyAction) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const newPayload = { vin: payload, ...get(phoenixData, 'contacts.results', {}) };
  try {
    const getModelsResults: phoenix.VehicleInfo = yield call(
      phoenix.getVehicleInfoFromVIN,
      newPayload
    );
    logger.log('Phoenix response -> ', getModelsResults);

    if (!getModelsResults || 'code' in getModelsResults || !('isVinFound' in getModelsResults)) {
      yield put(actions.phoenixGetVehicleInfoFromVINError(getModelsResults));
      yield put(actions.setError(getModelsResults || {}));
    } else yield put(actions.phoenixGetVehicleInfoFromVINSuccess(getModelsResults));
  } catch (err) {
    yield put(actions.phoenixGetVehicleInfoFromVINError());
    yield put(actions.setError({}));
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* getCreditsummary() {
  yield put(actions.setIsLoading({ isLoading: true }));
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const contactId = get(phoenixData, 'contacts.results.contactId', '');
  const leadId = get(phoenixData, 'contacts.results.leadId', '');

  try {
    const getCreditSummaryResult: phoenix.CreditResponse = yield call(phoenix.getCreditSummary, {
      contactId,
      leadId,
    });
    logger.log('Phoenix response -> ', getCreditSummaryResult);

    if (!getCreditSummaryResult || 'message' in getCreditSummaryResult) {
      yield put(actions.phoenixGetCreditSummaryError(getCreditSummaryResult));
      if (
        getCreditSummaryResult &&
        getCreditSummaryResult.message.includes(RECAPTCHA_ERROR_MESSAGE)
      )
        yield put(actions.setError(getCreditSummaryResult));
    } else yield put(actions.phoenixGetCreditSummarySuccess(getCreditSummaryResult));
  } catch (err) {
    yield put(actions.phoenixGetCreditSummaryError(err));
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* getYears() {
  try {
    const getYearsResults: phoenix.VehicleOptionsResponse = yield call(phoenix.getYears);
    logger.log('Phoenix response -> ', getYearsResults);

    if (!getYearsResults || 'message' in getYearsResults) {
      yield put(actions.setError(getYearsResults || {}));
      yield put(actions.phoenixGetYearsError(getYearsResults));
    } else yield put(actions.phoenixGetYearsSuccess(getYearsResults));
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixGetYearsError());
  }
}

function* getMakes(action: AnyAction) {
  try {
    const getMakesResults: phoenix.VehicleOptionsResponse = yield call(
      phoenix.getMakes,
      action.payload
    );
    logger.log('Phoenix response -> ', getMakesResults);

    if (!getMakesResults || 'message' in getMakesResults) {
      yield put(actions.setError(getMakesResults || {}));
      yield put(actions.phoenixGetYearsError(getMakesResults));
    } else yield put(actions.phoenixGetMakesSuccess(getMakesResults));
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixGetMakesError());
  }
}

function* getModels(action: AnyAction) {
  try {
    const getModelsResults: phoenix.VehicleOptionsResponse = yield call(
      phoenix.getModels,
      action.payload
    );
    logger.log('Phoenix response -> ', getModelsResults);

    if (!getModelsResults || 'message' in getModelsResults) {
      yield put(actions.setError(getModelsResults || {}));
      yield put(actions.phoenixGetModelsError(getModelsResults));
    } else yield put(actions.phoenixGetModelsSuccess(getModelsResults));
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixGetModelsError());
  }
}

function* postCreditScore({ payload }: PayloadAction<phoenix.CreditScoreData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  try {
    const tmpContactData = {
      contactId: '7f6d92fb-5d9f-4e91-9616-4748f9a8d0bd',
      leadId: '6f928c55-a835-446b-a1f3-b0e145be9f36',
    };
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const newPayload = {
      scoreRange: payload,
      ...get(phoenixData, 'contacts.results', tmpContactData),
    };

    const postCreditScoreResult: phoenix.CreditScoreResponse = yield call(
      phoenix.postCreditScore,
      newPayload
    );
    logger.log('Phoenix response -> ', postCreditScoreResult);

    if (!postCreditScoreResult || 'message' in postCreditScoreResult)
      yield put(actions.setError(postCreditScoreResult || {}));
    else yield put(actions.phoenixPostCreditScoreSuccess(postCreditScoreResult));
  } catch (err) {
    yield put(actions.setError({}));
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* getEstimatedOffers() {
  yield put(actions.setIsLoading({ isLoading: true }));
  try {
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const contactId = get(phoenixData, 'contacts.results.contactId', '');
    const leadId = get(phoenixData, 'contacts.results.leadId', '');

    const getEstimatedOffersResult: phoenix.EstimatedOffersResponse = yield call(
      phoenix.getEstimatedOffers,
      {
        contactId,
        leadId,
      }
    );

    logger.log('Phoenix response -> ', getEstimatedOffersResult);
    if (!getEstimatedOffersResult || 'message' in getEstimatedOffersResult) {
      yield put(actions.phoenixGetEstimatedOffersError(getEstimatedOffersResult));
      if (
        getEstimatedOffersResult &&
        getEstimatedOffersResult.message.includes(RECAPTCHA_ERROR_MESSAGE)
      )
        yield put(actions.setError(getEstimatedOffersResult || {}));
    } else {
      const estimatedOffers = getEstimatedOffersResult.filter(
        offer => offer && offer.dealId && offer.rate && offer.monthlyPaymentAmount && offer.term
      );
      if (estimatedOffers?.length === 1)
        yield put(actions.phoenixPostEstimatedOfferSuccess(estimatedOffers[0]));

      yield put(
        actions.phoenixGetEstimatedOffersSuccess(estimatedOffers.filter(offer => offer.offerType))
      );
    }
  } catch (err) {
    yield put(actions.phoenixGetEstimatedOffersError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* postEstimatedOffer({ payload }: PayloadAction<phoenix.EstimatedOfferData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const contactId = get(phoenixData, 'contacts.results.contactId', '');
  const newPayload = { ...payload, contactId };

  try {
    const postEstimatedOfferResult: phoenix.PostEstimatedOfferResponse = yield call(
      phoenix.postEstimatedOffer,
      newPayload
    );
    logger.log('Phoenix response -> ', postEstimatedOfferResult);

    if (
      !postEstimatedOfferResult ||
      'errors' in postEstimatedOfferResult ||
      'details' in postEstimatedOfferResult ||
      !('success' in postEstimatedOfferResult)
    ) {
      yield put(actions.setError(postEstimatedOfferResult || {}));
      yield put(actions.phoenixPostEstimatedOfferError(postEstimatedOfferResult));
    } else {
      yield put(actions.phoenixPostEstimatedOfferSuccess(postEstimatedOfferResult));
    }
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixPostEstimatedOfferError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* postCurrentAddress({ payload }: PayloadAction<phoenix.CurrentAddressData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const contactId = get(phoenixData, 'contacts.results.contactId', '');
  const newPayload = { ...payload, contactId };

  try {
    const postResults: phoenix.CurrentAddressResponse = yield call(
      phoenix.postCurrentAddress,
      newPayload
    );
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'details' in postResults) {
      yield put(actions.setError(postResults || {}));
      yield put(actions.phoenixPostCurrentAddressError(postResults));
    } else {
      yield put(actions.phoenixPostCurrentAddressSuccess(postResults));
    }
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixPostCurrentAddressError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* postNewAddress({ payload }: PayloadAction<phoenix.AddressData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const phoenixData: RootState = yield select(selectors.phoenixRoot);
  const contactId = get(phoenixData, 'contacts.results.contactId', '');
  const newPayload = { ...payload, contactId };

  try {
    const postResults: phoenix.AddressResponse = yield call(phoenix.postAddress, newPayload);
    logger.log('Phoenix response -> ', postResults);

    if (!postResults || 'errors' in postResults || 'details' in postResults) {
      yield put(actions.setError(postResults || {}));
      yield put(actions.phoenixPostAddressError(postResults));
    } else {
      yield put(actions.phoenixPostAddressSuccess(postResults));
    }
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixPostAddressError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* getProgressHistory({ payload }: PayloadAction<{ contactId: string; leadId: string }>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  try {
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const activeLeads = get(phoenixData, 'contacts.results.activeLeads', '');
    if (!activeLeads.length) yield put(actions.phoenixGetProgressHistoryError());
    const leadId = payload?.leadId || activeLeads[0].leadId;
    const contactId = payload?.contactId || get(phoenixData, 'contacts.results.contactId', '');

    const getProgressHistoryResults: phoenix.ProgressHistoryResponse = yield call(
      phoenix.getProgressHistory,
      leadId
    );
    logger.log('Phoenix response -> ', getProgressHistoryResults);

    if (!getProgressHistoryResults || 'message' in getProgressHistoryResults) {
      yield put(actions.setError(getProgressHistoryResults || {}));
      yield put(actions.phoenixGetProgressHistoryError(getProgressHistoryResults));
      yield put(actions.setIsLoading({ isLoading: false }));
    } else {
      yield put(
        actions.phoenixGetProgressHistorySuccess({
          ...getProgressHistoryResults,
          leadId,
          contactId,
        })
      );
      if (getProgressHistoryResults.screen === 'VehicleMoreInfo') {
        yield put(actions.phoenixGetVehicleInfoFromVIN(getProgressHistoryResults.vinNumber));
      }
    }
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixGetProgressHistoryError());
  }
}

function* postProgressHistory(action: AnyAction) {
  yield put(actions.setIsLoading({ isLoading: true }));
  try {
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const leadId = get(phoenixData, 'contacts.results.leadId', '');
    const postProgressHistoryResults: Record<string, string> = yield call(
      phoenix.postProgressHistory,
      {
        ...action.payload,
        leadId,
      }
    );
    logger.log('Phoenix response -> ', postProgressHistoryResults);
    if (!postProgressHistoryResults || 'message' in postProgressHistoryResults) {
      yield put(actions.setError(postProgressHistoryResults || {}));
    } else {
      yield put(
        actions.phoenixPostProgressHistorySuccess({
          completedScreen: action.payload.completedScreen.screen,
          nextScreen: action.payload.nextScreen,
        })
      );
    }
  } catch (err) {
    yield put(actions.setError({}));
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* postLeads(action: AnyAction) {
  yield put(actions.setIsLoading({ isLoading: true }));
  try {
    let phoenixData: RootState = yield select(selectors.phoenixRoot);
    const contactId = get(phoenixData, 'contacts.results.contactId', '');
    let isDisqualified = get(phoenixData, 'qualifier.isDisqualified', false);
    if (!isDisqualified) {
      yield take(
        (action: AnyAction) =>
          action.type === ActionTypes.PHOENIX_POST_QUALIFIERS_SUCCESS && action.payload
      );
    }
    const postLeadResults: Record<string, string> = yield call(phoenix.postLeads, {
      contactId,
    });
    logger.log('Phoenix response -> ', postLeadResults);

    if (!postLeadResults || 'message' in postLeadResults) {
      yield put(actions.phoenixPostLeadError(postLeadResults || {}));
      yield put(actions.setError(postLeadResults || {}));
    } else {
      yield put(actions.phoenixPostLeadSuccess(postLeadResults));
      yield put(actions.phoenixPostQualifiersSuccess(false));
    }
    const postProgressHistoryResults: Record<string, string> = yield call(
      phoenix.postProgressHistory,
      {
        leadId: postLeadResults.leadId,
        completedScreen: {
          screen: action.payload.screen,
          createdTimeUtc: new Date().toISOString(),
        },
        isVinEntered: false,
        nextScreen: action.payload.screen === 'Qualifier' ? action.payload.screen : 'VehicleInfo',
      }
    );
    logger.log('Phoenix response -> ', postProgressHistoryResults);

    if (!postProgressHistoryResults || 'message' in postProgressHistoryResults) {
      yield put(actions.setError(postProgressHistoryResults || {}));
    }
  } catch (err) {
    yield put(actions.phoenixPostLeadError({}));
    yield put(actions.setError({}));
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* updateSSN({ payload }: PayloadAction<phoenix.SSNData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  const appData: RootState = yield select(selectors.appRoot);
  logger.log('Phoenix updateSSN -> ', appData, payload);

  try {
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const contactId = get(phoenixData, 'contacts.results.contactId', '');
    const leadId = get(phoenixData, 'contacts.results.leadId', '');

    const ssnResult: phoenix.SSNResponse = yield call(phoenix.updateSSN, {
      contactId,
      leadId,
    });
    logger.log('Phoenix response -> ', ssnResult);

    if (!ssnResult || 'errors' in ssnResult || !('success' in ssnResult)) {
      yield put(actions.setError(ssnResult || {}));
      yield put(actions.phoenixUpdateSSNError(ssnResult));
      yield put(actions.setIsLoading({ isLoading: false }));
    } else {
      yield put(actions.phoenixUpdateSSNSuccess(ssnResult));
      yield put(actions.phoenixGetCreditSummary());
    }
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixUpdateSSNError());
    yield put(actions.setIsLoading({ isLoading: false }));
  }
}

function* plateToVin({ payload }: PayloadAction<phoenix.postPlateToVinData>) {
  yield put(actions.setIsLoading({ isLoading: true }));
  yield put(actions.phoenixPostPlateToVinError({}));
  const appData: RootState = yield select(selectors.appRoot);

  logger.log('Phoenix plateToVin -> ', appData, payload);

  try {
    const phoenixData: RootState = yield select(selectors.phoenixRoot);
    const leadId = get(phoenixData, 'contacts.results.leadId', '');
    const contactId = get(phoenixData, 'contacts.results.contactId', '');

    const request = {
      ...payload,
      leadId,
      contactId,
    };

    const postResults: phoenix.plateToVinResponse = yield call(phoenix.postPlateToVin, request);
    logger.log('Phoenix response -> ', postResults);
    const isVehicle10YearsOrOlder = postResults.year
      ? +postResults.year <= new Date().getFullYear() - 10
      : undefined;
    const data = { ...postResults, isVehicle10YearsOrOlder, isVinFound: postResults.isSuccess };

    if (!postResults || 'errors' in postResults || !('isSuccess' in postResults)) {
      yield put(actions.setError(postResults || {}));
      yield put(actions.phoenixPostPlateToVinError(postResults || {}));
    } else yield put(actions.phoenixPostPlateToVinSuccess(data));
  } catch (err) {
    yield put(actions.setError({}));
    yield put(actions.phoenixPostPlateToVinError());
  }
  yield put(actions.setIsLoading({ isLoading: false }));
}

function* phoenixSaga() {
  yield all([takeLatest(ActionTypes.PHOENIX_GET_CREDIT_SUMMARY, getCreditsummary)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_QUALIFIERS, postQualifiers)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_YEARS, getYears)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_MAKES, getMakes)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_MODELS, getModels)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_VEHICLE_INFO, getVehicleInfoFromVIN)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_CONTACT, postContact)]);
  yield all([takeLatest(ActionTypes.PHOENIX_UPDATE_SSN, updateSSN)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_CREDIT_SCORE, postCreditScore)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_ASSETS, postAssets)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_ASSET, postAsset)]);
  yield all([takeLatest(ActionTypes.PHOENIX_PUT_ASSET, putAsset)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS, getEstimatedOffers)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_ESTIMATED_OFFER, postEstimatedOffer)]);
  yield all([takeLatest(ActionTypes.PHOENIX_GET_PROGRESS_HISTORY, getProgressHistory)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_PROGRESS_HISTORY, postProgressHistory)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_LEAD, postLeads)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_ADDRESS, postNewAddress)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_CURRENT_ADDRESS, postCurrentAddress)]);
  yield all([takeLatest(ActionTypes.PHOENIX_POST_PLATE_TO_VIN, plateToVin)]);
}

export default phoenixSaga;
